// source: api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var annotations_pb = require('./annotations_pb.js');
goog.object.extend(proto, annotations_pb);
goog.exportSymbol('proto.org.couchers.api.core.BirthdateVerificationStatus', null, global);
goog.exportSymbol('proto.org.couchers.api.core.CancelFriendRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.FriendRequest', null, global);
goog.exportSymbol('proto.org.couchers.api.core.FriendRequest.FriendRequestStatus', null, global);
goog.exportSymbol('proto.org.couchers.api.core.GenderVerificationStatus', null, global);
goog.exportSymbol('proto.org.couchers.api.core.GetLiteUserReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.GetLiteUsersReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.GetLiteUsersRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.GetUserReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.HostingStatus', null, global);
goog.exportSymbol('proto.org.couchers.api.core.InitiateMediaUploadRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.LanguageAbility', null, global);
goog.exportSymbol('proto.org.couchers.api.core.LanguageAbility.Fluency', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ListBadgeUsersReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ListBadgeUsersRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ListFriendRequestsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ListFriendsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ListMutualFriendsReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ListMutualFriendsRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.LiteUser', null, global);
goog.exportSymbol('proto.org.couchers.api.core.LiteUserRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.LiteUserRes.RespCase', null, global);
goog.exportSymbol('proto.org.couchers.api.core.MeetupStatus', null, global);
goog.exportSymbol('proto.org.couchers.api.core.MutualFriend', null, global);
goog.exportSymbol('proto.org.couchers.api.core.NullableBoolValue', null, global);
goog.exportSymbol('proto.org.couchers.api.core.NullableStringValue', null, global);
goog.exportSymbol('proto.org.couchers.api.core.NullableUInt32Value', null, global);
goog.exportSymbol('proto.org.couchers.api.core.ParkingDetails', null, global);
goog.exportSymbol('proto.org.couchers.api.core.PingReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.PingRes', null, global);
goog.exportSymbol('proto.org.couchers.api.core.RepeatedLanguageAbilityValue', null, global);
goog.exportSymbol('proto.org.couchers.api.core.RepeatedStringValue', null, global);
goog.exportSymbol('proto.org.couchers.api.core.RespondFriendRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.SendFriendRequestReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.SleepingArrangement', null, global);
goog.exportSymbol('proto.org.couchers.api.core.SmokingLocation', null, global);
goog.exportSymbol('proto.org.couchers.api.core.UpdateProfileReq', null, global);
goog.exportSymbol('proto.org.couchers.api.core.User', null, global);
goog.exportSymbol('proto.org.couchers.api.core.User.FriendshipStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.SendFriendRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.SendFriendRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.SendFriendRequestReq.displayName = 'proto.org.couchers.api.core.SendFriendRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.FriendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.FriendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.FriendRequest.displayName = 'proto.org.couchers.api.core.FriendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.RespondFriendRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.RespondFriendRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.RespondFriendRequestReq.displayName = 'proto.org.couchers.api.core.RespondFriendRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.CancelFriendRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.CancelFriendRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.CancelFriendRequestReq.displayName = 'proto.org.couchers.api.core.CancelFriendRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.ListFriendRequestsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.ListFriendRequestsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.ListFriendRequestsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.ListFriendRequestsRes.displayName = 'proto.org.couchers.api.core.ListFriendRequestsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.ListFriendsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.ListFriendsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.ListFriendsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.ListFriendsRes.displayName = 'proto.org.couchers.api.core.ListFriendsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.ListMutualFriendsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.ListMutualFriendsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.ListMutualFriendsReq.displayName = 'proto.org.couchers.api.core.ListMutualFriendsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.ListMutualFriendsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.ListMutualFriendsRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.ListMutualFriendsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.ListMutualFriendsRes.displayName = 'proto.org.couchers.api.core.ListMutualFriendsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.PingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.PingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.PingReq.displayName = 'proto.org.couchers.api.core.PingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.PingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.PingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.PingRes.displayName = 'proto.org.couchers.api.core.PingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.MutualFriend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.MutualFriend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.MutualFriend.displayName = 'proto.org.couchers.api.core.MutualFriend';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.LanguageAbility = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.LanguageAbility, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.LanguageAbility.displayName = 'proto.org.couchers.api.core.LanguageAbility';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.NullableUInt32Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.NullableUInt32Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.NullableUInt32Value.displayName = 'proto.org.couchers.api.core.NullableUInt32Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.NullableBoolValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.NullableBoolValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.NullableBoolValue.displayName = 'proto.org.couchers.api.core.NullableBoolValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.NullableStringValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.NullableStringValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.NullableStringValue.displayName = 'proto.org.couchers.api.core.NullableStringValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.User.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.User.displayName = 'proto.org.couchers.api.core.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.GetUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.GetUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.GetUserReq.displayName = 'proto.org.couchers.api.core.GetUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.LiteUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.LiteUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.LiteUser.displayName = 'proto.org.couchers.api.core.LiteUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.GetLiteUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.GetLiteUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.GetLiteUserReq.displayName = 'proto.org.couchers.api.core.GetLiteUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.GetLiteUsersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.GetLiteUsersReq.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.GetLiteUsersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.GetLiteUsersReq.displayName = 'proto.org.couchers.api.core.GetLiteUsersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.LiteUserRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.org.couchers.api.core.LiteUserRes.oneofGroups_);
};
goog.inherits(proto.org.couchers.api.core.LiteUserRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.LiteUserRes.displayName = 'proto.org.couchers.api.core.LiteUserRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.GetLiteUsersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.GetLiteUsersRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.GetLiteUsersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.GetLiteUsersRes.displayName = 'proto.org.couchers.api.core.GetLiteUsersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.RepeatedStringValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.RepeatedStringValue.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.RepeatedStringValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.RepeatedStringValue.displayName = 'proto.org.couchers.api.core.RepeatedStringValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.RepeatedLanguageAbilityValue.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.RepeatedLanguageAbilityValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.RepeatedLanguageAbilityValue.displayName = 'proto.org.couchers.api.core.RepeatedLanguageAbilityValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.UpdateProfileReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.UpdateProfileReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.UpdateProfileReq.displayName = 'proto.org.couchers.api.core.UpdateProfileReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.InitiateMediaUploadRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.InitiateMediaUploadRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.InitiateMediaUploadRes.displayName = 'proto.org.couchers.api.core.InitiateMediaUploadRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.ListBadgeUsersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.org.couchers.api.core.ListBadgeUsersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.ListBadgeUsersReq.displayName = 'proto.org.couchers.api.core.ListBadgeUsersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.org.couchers.api.core.ListBadgeUsersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.org.couchers.api.core.ListBadgeUsersRes.repeatedFields_, null);
};
goog.inherits(proto.org.couchers.api.core.ListBadgeUsersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.org.couchers.api.core.ListBadgeUsersRes.displayName = 'proto.org.couchers.api.core.ListBadgeUsersRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.SendFriendRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.SendFriendRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.SendFriendRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.SendFriendRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.SendFriendRequestReq}
 */
proto.org.couchers.api.core.SendFriendRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.SendFriendRequestReq;
  return proto.org.couchers.api.core.SendFriendRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.SendFriendRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.SendFriendRequestReq}
 */
proto.org.couchers.api.core.SendFriendRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.SendFriendRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.SendFriendRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.SendFriendRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.SendFriendRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.SendFriendRequestReq.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.SendFriendRequestReq} returns this
 */
proto.org.couchers.api.core.SendFriendRequestReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.FriendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.FriendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.FriendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.FriendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    friendRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.FriendRequest}
 */
proto.org.couchers.api.core.FriendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.FriendRequest;
  return proto.org.couchers.api.core.FriendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.FriendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.FriendRequest}
 */
proto.org.couchers.api.core.FriendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFriendRequestId(value);
      break;
    case 2:
      var value = /** @type {!proto.org.couchers.api.core.FriendRequest.FriendRequestStatus} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.FriendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.FriendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.FriendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.FriendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFriendRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getSent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.org.couchers.api.core.FriendRequest.FriendRequestStatus = {
  PENDING: 0,
  ACCEPTED: 1
};

/**
 * optional int64 friend_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.FriendRequest.prototype.getFriendRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.FriendRequest} returns this
 */
proto.org.couchers.api.core.FriendRequest.prototype.setFriendRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FriendRequestStatus state = 2;
 * @return {!proto.org.couchers.api.core.FriendRequest.FriendRequestStatus}
 */
proto.org.couchers.api.core.FriendRequest.prototype.getState = function() {
  return /** @type {!proto.org.couchers.api.core.FriendRequest.FriendRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.org.couchers.api.core.FriendRequest.FriendRequestStatus} value
 * @return {!proto.org.couchers.api.core.FriendRequest} returns this
 */
proto.org.couchers.api.core.FriendRequest.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 user_id = 3;
 * @return {number}
 */
proto.org.couchers.api.core.FriendRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.FriendRequest} returns this
 */
proto.org.couchers.api.core.FriendRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool sent = 4;
 * @return {boolean}
 */
proto.org.couchers.api.core.FriendRequest.prototype.getSent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.FriendRequest} returns this
 */
proto.org.couchers.api.core.FriendRequest.prototype.setSent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.RespondFriendRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.RespondFriendRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.RespondFriendRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.RespondFriendRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    friendRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accept: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.RespondFriendRequestReq}
 */
proto.org.couchers.api.core.RespondFriendRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.RespondFriendRequestReq;
  return proto.org.couchers.api.core.RespondFriendRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.RespondFriendRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.RespondFriendRequestReq}
 */
proto.org.couchers.api.core.RespondFriendRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFriendRequestId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccept(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.RespondFriendRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.RespondFriendRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.RespondFriendRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.RespondFriendRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFriendRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccept();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 friend_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.RespondFriendRequestReq.prototype.getFriendRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.RespondFriendRequestReq} returns this
 */
proto.org.couchers.api.core.RespondFriendRequestReq.prototype.setFriendRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool accept = 2;
 * @return {boolean}
 */
proto.org.couchers.api.core.RespondFriendRequestReq.prototype.getAccept = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.RespondFriendRequestReq} returns this
 */
proto.org.couchers.api.core.RespondFriendRequestReq.prototype.setAccept = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.CancelFriendRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.CancelFriendRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.CancelFriendRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.CancelFriendRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    friendRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.CancelFriendRequestReq}
 */
proto.org.couchers.api.core.CancelFriendRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.CancelFriendRequestReq;
  return proto.org.couchers.api.core.CancelFriendRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.CancelFriendRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.CancelFriendRequestReq}
 */
proto.org.couchers.api.core.CancelFriendRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFriendRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.CancelFriendRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.CancelFriendRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.CancelFriendRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.CancelFriendRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFriendRequestId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 friend_request_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.CancelFriendRequestReq.prototype.getFriendRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.CancelFriendRequestReq} returns this
 */
proto.org.couchers.api.core.CancelFriendRequestReq.prototype.setFriendRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.ListFriendRequestsRes.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.ListFriendRequestsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.ListFriendRequestsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListFriendRequestsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    sentList: jspb.Message.toObjectList(msg.getSentList(),
    proto.org.couchers.api.core.FriendRequest.toObject, includeInstance),
    receivedList: jspb.Message.toObjectList(msg.getReceivedList(),
    proto.org.couchers.api.core.FriendRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.ListFriendRequestsRes}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.ListFriendRequestsRes;
  return proto.org.couchers.api.core.ListFriendRequestsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.ListFriendRequestsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.ListFriendRequestsRes}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.core.FriendRequest;
      reader.readMessage(value,proto.org.couchers.api.core.FriendRequest.deserializeBinaryFromReader);
      msg.addSent(value);
      break;
    case 2:
      var value = new proto.org.couchers.api.core.FriendRequest;
      reader.readMessage(value,proto.org.couchers.api.core.FriendRequest.deserializeBinaryFromReader);
      msg.addReceived(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.ListFriendRequestsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.ListFriendRequestsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListFriendRequestsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.core.FriendRequest.serializeBinaryToWriter
    );
  }
  f = message.getReceivedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.org.couchers.api.core.FriendRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FriendRequest sent = 1;
 * @return {!Array<!proto.org.couchers.api.core.FriendRequest>}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.getSentList = function() {
  return /** @type{!Array<!proto.org.couchers.api.core.FriendRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.core.FriendRequest, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.FriendRequest>} value
 * @return {!proto.org.couchers.api.core.ListFriendRequestsRes} returns this
*/
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.setSentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.core.FriendRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.FriendRequest}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.addSent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.core.FriendRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.ListFriendRequestsRes} returns this
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.clearSentList = function() {
  return this.setSentList([]);
};


/**
 * repeated FriendRequest received = 2;
 * @return {!Array<!proto.org.couchers.api.core.FriendRequest>}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.getReceivedList = function() {
  return /** @type{!Array<!proto.org.couchers.api.core.FriendRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.core.FriendRequest, 2));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.FriendRequest>} value
 * @return {!proto.org.couchers.api.core.ListFriendRequestsRes} returns this
*/
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.setReceivedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.org.couchers.api.core.FriendRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.FriendRequest}
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.addReceived = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.org.couchers.api.core.FriendRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.ListFriendRequestsRes} returns this
 */
proto.org.couchers.api.core.ListFriendRequestsRes.prototype.clearReceivedList = function() {
  return this.setReceivedList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.ListFriendsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.ListFriendsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.ListFriendsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.ListFriendsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListFriendsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.ListFriendsRes}
 */
proto.org.couchers.api.core.ListFriendsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.ListFriendsRes;
  return proto.org.couchers.api.core.ListFriendsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.ListFriendsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.ListFriendsRes}
 */
proto.org.couchers.api.core.ListFriendsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.ListFriendsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.ListFriendsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.ListFriendsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListFriendsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 user_ids = 1;
 * @return {!Array<number>}
 */
proto.org.couchers.api.core.ListFriendsRes.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.org.couchers.api.core.ListFriendsRes} returns this
 */
proto.org.couchers.api.core.ListFriendsRes.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.ListFriendsRes} returns this
 */
proto.org.couchers.api.core.ListFriendsRes.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.ListFriendsRes} returns this
 */
proto.org.couchers.api.core.ListFriendsRes.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.ListMutualFriendsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.ListMutualFriendsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.ListMutualFriendsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListMutualFriendsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.ListMutualFriendsReq}
 */
proto.org.couchers.api.core.ListMutualFriendsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.ListMutualFriendsReq;
  return proto.org.couchers.api.core.ListMutualFriendsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.ListMutualFriendsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.ListMutualFriendsReq}
 */
proto.org.couchers.api.core.ListMutualFriendsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.ListMutualFriendsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.ListMutualFriendsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.ListMutualFriendsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListMutualFriendsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.ListMutualFriendsReq.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.ListMutualFriendsReq} returns this
 */
proto.org.couchers.api.core.ListMutualFriendsReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.ListMutualFriendsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.ListMutualFriendsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.ListMutualFriendsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.ListMutualFriendsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListMutualFriendsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    mutualFriendsList: jspb.Message.toObjectList(msg.getMutualFriendsList(),
    proto.org.couchers.api.core.MutualFriend.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.ListMutualFriendsRes}
 */
proto.org.couchers.api.core.ListMutualFriendsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.ListMutualFriendsRes;
  return proto.org.couchers.api.core.ListMutualFriendsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.ListMutualFriendsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.ListMutualFriendsRes}
 */
proto.org.couchers.api.core.ListMutualFriendsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.core.MutualFriend;
      reader.readMessage(value,proto.org.couchers.api.core.MutualFriend.deserializeBinaryFromReader);
      msg.addMutualFriends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.ListMutualFriendsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.ListMutualFriendsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.ListMutualFriendsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListMutualFriendsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMutualFriendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.core.MutualFriend.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MutualFriend mutual_friends = 1;
 * @return {!Array<!proto.org.couchers.api.core.MutualFriend>}
 */
proto.org.couchers.api.core.ListMutualFriendsRes.prototype.getMutualFriendsList = function() {
  return /** @type{!Array<!proto.org.couchers.api.core.MutualFriend>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.core.MutualFriend, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.MutualFriend>} value
 * @return {!proto.org.couchers.api.core.ListMutualFriendsRes} returns this
*/
proto.org.couchers.api.core.ListMutualFriendsRes.prototype.setMutualFriendsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.core.MutualFriend=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.MutualFriend}
 */
proto.org.couchers.api.core.ListMutualFriendsRes.prototype.addMutualFriends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.core.MutualFriend, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.ListMutualFriendsRes} returns this
 */
proto.org.couchers.api.core.ListMutualFriendsRes.prototype.clearMutualFriendsList = function() {
  return this.setMutualFriendsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.PingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.PingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.PingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.PingReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.PingReq}
 */
proto.org.couchers.api.core.PingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.PingReq;
  return proto.org.couchers.api.core.PingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.PingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.PingReq}
 */
proto.org.couchers.api.core.PingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.PingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.PingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.PingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.PingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.PingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.PingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.PingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.PingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.org.couchers.api.core.User.toObject(includeInstance, f),
    unseenMessageCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unseenSentHostRequestCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unseenReceivedHostRequestCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pendingFriendRequestCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.PingRes}
 */
proto.org.couchers.api.core.PingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.PingRes;
  return proto.org.couchers.api.core.PingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.PingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.PingRes}
 */
proto.org.couchers.api.core.PingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.core.User;
      reader.readMessage(value,proto.org.couchers.api.core.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnseenMessageCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnseenSentHostRequestCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnseenReceivedHostRequestCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPendingFriendRequestCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.PingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.PingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.PingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.PingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.org.couchers.api.core.User.serializeBinaryToWriter
    );
  }
  f = message.getUnseenMessageCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getUnseenSentHostRequestCount();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUnseenReceivedHostRequestCount();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPendingFriendRequestCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.org.couchers.api.core.User}
 */
proto.org.couchers.api.core.PingRes.prototype.getUser = function() {
  return /** @type{?proto.org.couchers.api.core.User} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.User, 1));
};


/**
 * @param {?proto.org.couchers.api.core.User|undefined} value
 * @return {!proto.org.couchers.api.core.PingRes} returns this
*/
proto.org.couchers.api.core.PingRes.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.PingRes} returns this
 */
proto.org.couchers.api.core.PingRes.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.PingRes.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 unseen_message_count = 2;
 * @return {number}
 */
proto.org.couchers.api.core.PingRes.prototype.getUnseenMessageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.PingRes} returns this
 */
proto.org.couchers.api.core.PingRes.prototype.setUnseenMessageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 unseen_sent_host_request_count = 5;
 * @return {number}
 */
proto.org.couchers.api.core.PingRes.prototype.getUnseenSentHostRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.PingRes} returns this
 */
proto.org.couchers.api.core.PingRes.prototype.setUnseenSentHostRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 unseen_received_host_request_count = 6;
 * @return {number}
 */
proto.org.couchers.api.core.PingRes.prototype.getUnseenReceivedHostRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.PingRes} returns this
 */
proto.org.couchers.api.core.PingRes.prototype.setUnseenReceivedHostRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 pending_friend_request_count = 4;
 * @return {number}
 */
proto.org.couchers.api.core.PingRes.prototype.getPendingFriendRequestCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.PingRes} returns this
 */
proto.org.couchers.api.core.PingRes.prototype.setPendingFriendRequestCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.MutualFriend.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.MutualFriend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.MutualFriend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.MutualFriend.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.MutualFriend}
 */
proto.org.couchers.api.core.MutualFriend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.MutualFriend;
  return proto.org.couchers.api.core.MutualFriend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.MutualFriend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.MutualFriend}
 */
proto.org.couchers.api.core.MutualFriend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.MutualFriend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.MutualFriend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.MutualFriend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.MutualFriend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.MutualFriend.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.MutualFriend} returns this
 */
proto.org.couchers.api.core.MutualFriend.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.org.couchers.api.core.MutualFriend.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.MutualFriend} returns this
 */
proto.org.couchers.api.core.MutualFriend.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.org.couchers.api.core.MutualFriend.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.MutualFriend} returns this
 */
proto.org.couchers.api.core.MutualFriend.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.LanguageAbility.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.LanguageAbility.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.LanguageAbility} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.LanguageAbility.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fluency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.LanguageAbility}
 */
proto.org.couchers.api.core.LanguageAbility.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.LanguageAbility;
  return proto.org.couchers.api.core.LanguageAbility.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.LanguageAbility} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.LanguageAbility}
 */
proto.org.couchers.api.core.LanguageAbility.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {!proto.org.couchers.api.core.LanguageAbility.Fluency} */ (reader.readEnum());
      msg.setFluency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.LanguageAbility.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.LanguageAbility.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.LanguageAbility} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.LanguageAbility.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFluency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.org.couchers.api.core.LanguageAbility.Fluency = {
  FLUENCY_UNKNOWN: 0,
  FLUENCY_BEGINNER: 1,
  FLUENCY_CONVERSATIONAL: 2,
  FLUENCY_FLUENT: 3
};

/**
 * optional string code = 1;
 * @return {string}
 */
proto.org.couchers.api.core.LanguageAbility.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LanguageAbility} returns this
 */
proto.org.couchers.api.core.LanguageAbility.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Fluency fluency = 2;
 * @return {!proto.org.couchers.api.core.LanguageAbility.Fluency}
 */
proto.org.couchers.api.core.LanguageAbility.prototype.getFluency = function() {
  return /** @type {!proto.org.couchers.api.core.LanguageAbility.Fluency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.org.couchers.api.core.LanguageAbility.Fluency} value
 * @return {!proto.org.couchers.api.core.LanguageAbility} returns this
 */
proto.org.couchers.api.core.LanguageAbility.prototype.setFluency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.NullableUInt32Value.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.NullableUInt32Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.NullableUInt32Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.NullableUInt32Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    isNull: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.NullableUInt32Value}
 */
proto.org.couchers.api.core.NullableUInt32Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.NullableUInt32Value;
  return proto.org.couchers.api.core.NullableUInt32Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.NullableUInt32Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.NullableUInt32Value}
 */
proto.org.couchers.api.core.NullableUInt32Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNull(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.NullableUInt32Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.NullableUInt32Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.NullableUInt32Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.NullableUInt32Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsNull();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional bool is_null = 1;
 * @return {boolean}
 */
proto.org.couchers.api.core.NullableUInt32Value.prototype.getIsNull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.NullableUInt32Value} returns this
 */
proto.org.couchers.api.core.NullableUInt32Value.prototype.setIsNull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional uint32 value = 2;
 * @return {number}
 */
proto.org.couchers.api.core.NullableUInt32Value.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.NullableUInt32Value} returns this
 */
proto.org.couchers.api.core.NullableUInt32Value.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.NullableBoolValue.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.NullableBoolValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.NullableBoolValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.NullableBoolValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    isNull: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    value: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.NullableBoolValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.NullableBoolValue;
  return proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.NullableBoolValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNull(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.NullableBoolValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.NullableBoolValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsNull();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool is_null = 1;
 * @return {boolean}
 */
proto.org.couchers.api.core.NullableBoolValue.prototype.getIsNull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.NullableBoolValue} returns this
 */
proto.org.couchers.api.core.NullableBoolValue.prototype.setIsNull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool value = 2;
 * @return {boolean}
 */
proto.org.couchers.api.core.NullableBoolValue.prototype.getValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.NullableBoolValue} returns this
 */
proto.org.couchers.api.core.NullableBoolValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.NullableStringValue.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.NullableStringValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.NullableStringValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.NullableStringValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    isNull: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.NullableStringValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.NullableStringValue;
  return proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.NullableStringValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNull(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.NullableStringValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.NullableStringValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsNull();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_null = 1;
 * @return {boolean}
 */
proto.org.couchers.api.core.NullableStringValue.prototype.getIsNull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.NullableStringValue} returns this
 */
proto.org.couchers.api.core.NullableStringValue.prototype.setIsNull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.org.couchers.api.core.NullableStringValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.NullableStringValue} returns this
 */
proto.org.couchers.api.core.NullableStringValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.User.repeatedFields_ = [40,41,37,38];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.User.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hometown: jspb.Message.getFieldWithDefault(msg, 100, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 122, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    verification: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    communityStanding: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    numReferences: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pronouns: jspb.Message.getFieldWithDefault(msg, 101, ""),
    age: jspb.Message.getFieldWithDefault(msg, 9, 0),
    joined: (f = msg.getJoined()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastActive: (f = msg.getLastActive()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    hostingStatus: jspb.Message.getFieldWithDefault(msg, 13, 0),
    meetupStatus: jspb.Message.getFieldWithDefault(msg, 102, 0),
    occupation: jspb.Message.getFieldWithDefault(msg, 14, ""),
    education: jspb.Message.getFieldWithDefault(msg, 103, ""),
    aboutMe: jspb.Message.getFieldWithDefault(msg, 15, ""),
    thingsILike: jspb.Message.getFieldWithDefault(msg, 105, ""),
    aboutPlace: jspb.Message.getFieldWithDefault(msg, 16, ""),
    regionsVisitedList: (f = jspb.Message.getRepeatedField(msg, 40)) == null ? undefined : f,
    regionsLivedList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f,
    additionalInformation: jspb.Message.getFieldWithDefault(msg, 106, ""),
    friends: jspb.Message.getFieldWithDefault(msg, 20, 0),
    pendingFriendRequest: (f = msg.getPendingFriendRequest()) && proto.org.couchers.api.core.FriendRequest.toObject(includeInstance, f),
    maxGuests: (f = msg.getMaxGuests()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    lastMinute: (f = msg.getLastMinute()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hasPets: (f = msg.getHasPets()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    acceptsPets: (f = msg.getAcceptsPets()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    petDetails: (f = msg.getPetDetails()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    hasKids: (f = msg.getHasKids()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    acceptsKids: (f = msg.getAcceptsKids()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    kidDetails: (f = msg.getKidDetails()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    hasHousemates: (f = msg.getHasHousemates()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    housemateDetails: (f = msg.getHousemateDetails()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    wheelchairAccessible: (f = msg.getWheelchairAccessible()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    smokingAllowed: jspb.Message.getFieldWithDefault(msg, 28, 0),
    smokesAtHome: (f = msg.getSmokesAtHome()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    drinkingAllowed: (f = msg.getDrinkingAllowed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    drinksAtHome: (f = msg.getDrinksAtHome()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    otherHostInfo: (f = msg.getOtherHostInfo()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sleepingArrangement: jspb.Message.getFieldWithDefault(msg, 117, 0),
    sleepingDetails: (f = msg.getSleepingDetails()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    area: (f = msg.getArea()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    houseRules: (f = msg.getHouseRules()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    parking: (f = msg.getParking()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    parkingDetails: jspb.Message.getFieldWithDefault(msg, 120, 0),
    campingOk: (f = msg.getCampingOk()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 32, ""),
    avatarThumbnailUrl: jspb.Message.getFieldWithDefault(msg, 44, ""),
    languageAbilitiesList: jspb.Message.toObjectList(msg.getLanguageAbilitiesList(),
    proto.org.couchers.api.core.LanguageAbility.toObject, includeInstance),
    badgesList: (f = jspb.Message.getRepeatedField(msg, 38)) == null ? undefined : f,
    hasStrongVerification: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    birthdateVerificationStatus: jspb.Message.getFieldWithDefault(msg, 42, 0),
    genderVerificationStatus: jspb.Message.getFieldWithDefault(msg, 43, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.User}
 */
proto.org.couchers.api.core.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.User;
  return proto.org.couchers.api.core.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.User}
 */
proto.org.couchers.api.core.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setHometown(value);
      break;
    case 122:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVerification(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommunityStanding(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumReferences(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGender(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setPronouns(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAge(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setJoined(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastActive(value);
      break;
    case 13:
      var value = /** @type {!proto.org.couchers.api.core.HostingStatus} */ (reader.readEnum());
      msg.setHostingStatus(value);
      break;
    case 102:
      var value = /** @type {!proto.org.couchers.api.core.MeetupStatus} */ (reader.readEnum());
      msg.setMeetupStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupation(value);
      break;
    case 103:
      var value = /** @type {string} */ (reader.readString());
      msg.setEducation(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutMe(value);
      break;
    case 105:
      var value = /** @type {string} */ (reader.readString());
      msg.setThingsILike(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAboutPlace(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionsVisited(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.addRegionsLived(value);
      break;
    case 106:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalInformation(value);
      break;
    case 20:
      var value = /** @type {!proto.org.couchers.api.core.User.FriendshipStatus} */ (reader.readEnum());
      msg.setFriends(value);
      break;
    case 36:
      var value = new proto.org.couchers.api.core.FriendRequest;
      reader.readMessage(value,proto.org.couchers.api.core.FriendRequest.deserializeBinaryFromReader);
      msg.setPendingFriendRequest(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMaxGuests(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setLastMinute(value);
      break;
    case 107:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasPets(value);
      break;
    case 25:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAcceptsPets(value);
      break;
    case 108:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPetDetails(value);
      break;
    case 109:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasKids(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setAcceptsKids(value);
      break;
    case 110:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setKidDetails(value);
      break;
    case 111:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasHousemates(value);
      break;
    case 112:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHousemateDetails(value);
      break;
    case 27:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setWheelchairAccessible(value);
      break;
    case 28:
      var value = /** @type {!proto.org.couchers.api.core.SmokingLocation} */ (reader.readEnum());
      msg.setSmokingAllowed(value);
      break;
    case 113:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setSmokesAtHome(value);
      break;
    case 114:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDrinkingAllowed(value);
      break;
    case 115:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDrinksAtHome(value);
      break;
    case 116:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOtherHostInfo(value);
      break;
    case 117:
      var value = /** @type {!proto.org.couchers.api.core.SleepingArrangement} */ (reader.readEnum());
      msg.setSleepingArrangement(value);
      break;
    case 118:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSleepingDetails(value);
      break;
    case 30:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 31:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHouseRules(value);
      break;
    case 119:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setParking(value);
      break;
    case 120:
      var value = /** @type {!proto.org.couchers.api.core.ParkingDetails} */ (reader.readEnum());
      msg.setParkingDetails(value);
      break;
    case 121:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCampingOk(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarThumbnailUrl(value);
      break;
    case 37:
      var value = new proto.org.couchers.api.core.LanguageAbility;
      reader.readMessage(value,proto.org.couchers.api.core.LanguageAbility.deserializeBinaryFromReader);
      msg.addLanguageAbilities(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.addBadges(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStrongVerification(value);
      break;
    case 42:
      var value = /** @type {!proto.org.couchers.api.core.BirthdateVerificationStatus} */ (reader.readEnum());
      msg.setBirthdateVerificationStatus(value);
      break;
    case 43:
      var value = /** @type {!proto.org.couchers.api.core.GenderVerificationStatus} */ (reader.readEnum());
      msg.setGenderVerificationStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHometown();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      122,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getVerification();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getCommunityStanding();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getNumReferences();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getGender();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPronouns();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getJoined();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastActive();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getHostingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getMeetupStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      102,
      f
    );
  }
  f = message.getOccupation();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEducation();
  if (f.length > 0) {
    writer.writeString(
      103,
      f
    );
  }
  f = message.getAboutMe();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getThingsILike();
  if (f.length > 0) {
    writer.writeString(
      105,
      f
    );
  }
  f = message.getAboutPlace();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getRegionsVisitedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      40,
      f
    );
  }
  f = message.getRegionsLivedList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      41,
      f
    );
  }
  f = message.getAdditionalInformation();
  if (f.length > 0) {
    writer.writeString(
      106,
      f
    );
  }
  f = message.getFriends();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getPendingFriendRequest();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.org.couchers.api.core.FriendRequest.serializeBinaryToWriter
    );
  }
  f = message.getMaxGuests();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getLastMinute();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasPets();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAcceptsPets();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPetDetails();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHasKids();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAcceptsKids();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getKidDetails();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHasHousemates();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHousemateDetails();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getWheelchairAccessible();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSmokingAllowed();
  if (f !== 0.0) {
    writer.writeEnum(
      28,
      f
    );
  }
  f = message.getSmokesAtHome();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDrinkingAllowed();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDrinksAtHome();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOtherHostInfo();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSleepingArrangement();
  if (f !== 0.0) {
    writer.writeEnum(
      117,
      f
    );
  }
  f = message.getSleepingDetails();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHouseRules();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getParking();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getParkingDetails();
  if (f !== 0.0) {
    writer.writeEnum(
      120,
      f
    );
  }
  f = message.getCampingOk();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAvatarThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getLanguageAbilitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      37,
      f,
      proto.org.couchers.api.core.LanguageAbility.serializeBinaryToWriter
    );
  }
  f = message.getBadgesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      38,
      f
    );
  }
  f = message.getHasStrongVerification();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getBirthdateVerificationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      42,
      f
    );
  }
  f = message.getGenderVerificationStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      43,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.org.couchers.api.core.User.FriendshipStatus = {
  NOT_FRIENDS: 0,
  FRIENDS: 1,
  PENDING: 2,
  NA: 3
};

/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string hometown = 100;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getHometown = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setHometown = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional string timezone = 122;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 122, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 122, value);
};


/**
 * optional double lat = 33;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional double lng = 34;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double radius = 35;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double verification = 5;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getVerification = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setVerification = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double community_standing = 6;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getCommunityStanding = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setCommunityStanding = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional uint32 num_references = 7;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getNumReferences = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setNumReferences = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string gender = 8;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setGender = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string pronouns = 101;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getPronouns = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setPronouns = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional uint32 age = 9;
 * @return {number}
 */
proto.org.couchers.api.core.User.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp joined = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.core.User.prototype.getJoined = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setJoined = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearJoined = function() {
  return this.setJoined(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasJoined = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Timestamp last_active = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.core.User.prototype.getLastActive = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setLastActive = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearLastActive = function() {
  return this.setLastActive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasLastActive = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional HostingStatus hosting_status = 13;
 * @return {!proto.org.couchers.api.core.HostingStatus}
 */
proto.org.couchers.api.core.User.prototype.getHostingStatus = function() {
  return /** @type {!proto.org.couchers.api.core.HostingStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.org.couchers.api.core.HostingStatus} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setHostingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional MeetupStatus meetup_status = 102;
 * @return {!proto.org.couchers.api.core.MeetupStatus}
 */
proto.org.couchers.api.core.User.prototype.getMeetupStatus = function() {
  return /** @type {!proto.org.couchers.api.core.MeetupStatus} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {!proto.org.couchers.api.core.MeetupStatus} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setMeetupStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 102, value);
};


/**
 * optional string occupation = 14;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string education = 103;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getEducation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 103, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setEducation = function(value) {
  return jspb.Message.setProto3StringField(this, 103, value);
};


/**
 * optional string about_me = 15;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getAboutMe = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setAboutMe = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string things_i_like = 105;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getThingsILike = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 105, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setThingsILike = function(value) {
  return jspb.Message.setProto3StringField(this, 105, value);
};


/**
 * optional string about_place = 16;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getAboutPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setAboutPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated string regions_visited = 40;
 * @return {!Array<string>}
 */
proto.org.couchers.api.core.User.prototype.getRegionsVisitedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 40));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setRegionsVisitedList = function(value) {
  return jspb.Message.setField(this, 40, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.addRegionsVisited = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 40, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearRegionsVisitedList = function() {
  return this.setRegionsVisitedList([]);
};


/**
 * repeated string regions_lived = 41;
 * @return {!Array<string>}
 */
proto.org.couchers.api.core.User.prototype.getRegionsLivedList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setRegionsLivedList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.addRegionsLived = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearRegionsLivedList = function() {
  return this.setRegionsLivedList([]);
};


/**
 * optional string additional_information = 106;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getAdditionalInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 106, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setAdditionalInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 106, value);
};


/**
 * optional FriendshipStatus friends = 20;
 * @return {!proto.org.couchers.api.core.User.FriendshipStatus}
 */
proto.org.couchers.api.core.User.prototype.getFriends = function() {
  return /** @type {!proto.org.couchers.api.core.User.FriendshipStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.org.couchers.api.core.User.FriendshipStatus} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setFriends = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional FriendRequest pending_friend_request = 36;
 * @return {?proto.org.couchers.api.core.FriendRequest}
 */
proto.org.couchers.api.core.User.prototype.getPendingFriendRequest = function() {
  return /** @type{?proto.org.couchers.api.core.FriendRequest} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.FriendRequest, 36));
};


/**
 * @param {?proto.org.couchers.api.core.FriendRequest|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setPendingFriendRequest = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearPendingFriendRequest = function() {
  return this.setPendingFriendRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasPendingFriendRequest = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional google.protobuf.UInt32Value max_guests = 22;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.org.couchers.api.core.User.prototype.getMaxGuests = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 22));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setMaxGuests = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearMaxGuests = function() {
  return this.setMaxGuests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasMaxGuests = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.BoolValue last_minute = 24;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getLastMinute = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 24));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setLastMinute = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearLastMinute = function() {
  return this.setLastMinute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasLastMinute = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional google.protobuf.BoolValue has_pets = 107;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getHasPets = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 107));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setHasPets = function(value) {
  return jspb.Message.setWrapperField(this, 107, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearHasPets = function() {
  return this.setHasPets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasHasPets = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional google.protobuf.BoolValue accepts_pets = 25;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getAcceptsPets = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 25));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setAcceptsPets = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearAcceptsPets = function() {
  return this.setAcceptsPets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasAcceptsPets = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional google.protobuf.StringValue pet_details = 108;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getPetDetails = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 108));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setPetDetails = function(value) {
  return jspb.Message.setWrapperField(this, 108, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearPetDetails = function() {
  return this.setPetDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasPetDetails = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional google.protobuf.BoolValue has_kids = 109;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getHasKids = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 109));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setHasKids = function(value) {
  return jspb.Message.setWrapperField(this, 109, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearHasKids = function() {
  return this.setHasKids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasHasKids = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional google.protobuf.BoolValue accepts_kids = 26;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getAcceptsKids = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 26));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setAcceptsKids = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearAcceptsKids = function() {
  return this.setAcceptsKids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasAcceptsKids = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.StringValue kid_details = 110;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getKidDetails = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 110));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setKidDetails = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearKidDetails = function() {
  return this.setKidDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasKidDetails = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional google.protobuf.BoolValue has_housemates = 111;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getHasHousemates = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 111));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setHasHousemates = function(value) {
  return jspb.Message.setWrapperField(this, 111, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearHasHousemates = function() {
  return this.setHasHousemates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasHasHousemates = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional google.protobuf.StringValue housemate_details = 112;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getHousemateDetails = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 112));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setHousemateDetails = function(value) {
  return jspb.Message.setWrapperField(this, 112, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearHousemateDetails = function() {
  return this.setHousemateDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasHousemateDetails = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional google.protobuf.BoolValue wheelchair_accessible = 27;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getWheelchairAccessible = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 27));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setWheelchairAccessible = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearWheelchairAccessible = function() {
  return this.setWheelchairAccessible(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasWheelchairAccessible = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional SmokingLocation smoking_allowed = 28;
 * @return {!proto.org.couchers.api.core.SmokingLocation}
 */
proto.org.couchers.api.core.User.prototype.getSmokingAllowed = function() {
  return /** @type {!proto.org.couchers.api.core.SmokingLocation} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {!proto.org.couchers.api.core.SmokingLocation} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setSmokingAllowed = function(value) {
  return jspb.Message.setProto3EnumField(this, 28, value);
};


/**
 * optional google.protobuf.BoolValue smokes_at_home = 113;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getSmokesAtHome = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 113));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setSmokesAtHome = function(value) {
  return jspb.Message.setWrapperField(this, 113, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearSmokesAtHome = function() {
  return this.setSmokesAtHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasSmokesAtHome = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional google.protobuf.BoolValue drinking_allowed = 114;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getDrinkingAllowed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 114));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setDrinkingAllowed = function(value) {
  return jspb.Message.setWrapperField(this, 114, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearDrinkingAllowed = function() {
  return this.setDrinkingAllowed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasDrinkingAllowed = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional google.protobuf.BoolValue drinks_at_home = 115;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getDrinksAtHome = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 115));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setDrinksAtHome = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearDrinksAtHome = function() {
  return this.setDrinksAtHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasDrinksAtHome = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional google.protobuf.StringValue other_host_info = 116;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getOtherHostInfo = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 116));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setOtherHostInfo = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearOtherHostInfo = function() {
  return this.setOtherHostInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasOtherHostInfo = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional SleepingArrangement sleeping_arrangement = 117;
 * @return {!proto.org.couchers.api.core.SleepingArrangement}
 */
proto.org.couchers.api.core.User.prototype.getSleepingArrangement = function() {
  return /** @type {!proto.org.couchers.api.core.SleepingArrangement} */ (jspb.Message.getFieldWithDefault(this, 117, 0));
};


/**
 * @param {!proto.org.couchers.api.core.SleepingArrangement} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setSleepingArrangement = function(value) {
  return jspb.Message.setProto3EnumField(this, 117, value);
};


/**
 * optional google.protobuf.StringValue sleeping_details = 118;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getSleepingDetails = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 118));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setSleepingDetails = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearSleepingDetails = function() {
  return this.setSleepingDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasSleepingDetails = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional google.protobuf.StringValue area = 30;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getArea = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 30));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasArea = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.protobuf.StringValue house_rules = 31;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.User.prototype.getHouseRules = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 31));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setHouseRules = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearHouseRules = function() {
  return this.setHouseRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasHouseRules = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.BoolValue parking = 119;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getParking = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 119));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setParking = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearParking = function() {
  return this.setParking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasParking = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional ParkingDetails parking_details = 120;
 * @return {!proto.org.couchers.api.core.ParkingDetails}
 */
proto.org.couchers.api.core.User.prototype.getParkingDetails = function() {
  return /** @type {!proto.org.couchers.api.core.ParkingDetails} */ (jspb.Message.getFieldWithDefault(this, 120, 0));
};


/**
 * @param {!proto.org.couchers.api.core.ParkingDetails} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setParkingDetails = function(value) {
  return jspb.Message.setProto3EnumField(this, 120, value);
};


/**
 * optional google.protobuf.BoolValue camping_ok = 121;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.org.couchers.api.core.User.prototype.getCampingOk = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 121));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setCampingOk = function(value) {
  return jspb.Message.setWrapperField(this, 121, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearCampingOk = function() {
  return this.setCampingOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.hasCampingOk = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional string avatar_url = 32;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string avatar_thumbnail_url = 44;
 * @return {string}
 */
proto.org.couchers.api.core.User.prototype.getAvatarThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setAvatarThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * repeated LanguageAbility language_abilities = 37;
 * @return {!Array<!proto.org.couchers.api.core.LanguageAbility>}
 */
proto.org.couchers.api.core.User.prototype.getLanguageAbilitiesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.core.LanguageAbility>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.core.LanguageAbility, 37));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.LanguageAbility>} value
 * @return {!proto.org.couchers.api.core.User} returns this
*/
proto.org.couchers.api.core.User.prototype.setLanguageAbilitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 37, value);
};


/**
 * @param {!proto.org.couchers.api.core.LanguageAbility=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.LanguageAbility}
 */
proto.org.couchers.api.core.User.prototype.addLanguageAbilities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 37, opt_value, proto.org.couchers.api.core.LanguageAbility, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearLanguageAbilitiesList = function() {
  return this.setLanguageAbilitiesList([]);
};


/**
 * repeated string badges = 38;
 * @return {!Array<string>}
 */
proto.org.couchers.api.core.User.prototype.getBadgesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 38));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setBadgesList = function(value) {
  return jspb.Message.setField(this, 38, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.addBadges = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 38, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.clearBadgesList = function() {
  return this.setBadgesList([]);
};


/**
 * optional bool has_strong_verification = 39;
 * @return {boolean}
 */
proto.org.couchers.api.core.User.prototype.getHasStrongVerification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setHasStrongVerification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional BirthdateVerificationStatus birthdate_verification_status = 42;
 * @return {!proto.org.couchers.api.core.BirthdateVerificationStatus}
 */
proto.org.couchers.api.core.User.prototype.getBirthdateVerificationStatus = function() {
  return /** @type {!proto.org.couchers.api.core.BirthdateVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {!proto.org.couchers.api.core.BirthdateVerificationStatus} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setBirthdateVerificationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 42, value);
};


/**
 * optional GenderVerificationStatus gender_verification_status = 43;
 * @return {!proto.org.couchers.api.core.GenderVerificationStatus}
 */
proto.org.couchers.api.core.User.prototype.getGenderVerificationStatus = function() {
  return /** @type {!proto.org.couchers.api.core.GenderVerificationStatus} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {!proto.org.couchers.api.core.GenderVerificationStatus} value
 * @return {!proto.org.couchers.api.core.User} returns this
 */
proto.org.couchers.api.core.User.prototype.setGenderVerificationStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 43, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.GetUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.GetUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.GetUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.GetUserReq}
 */
proto.org.couchers.api.core.GetUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.GetUserReq;
  return proto.org.couchers.api.core.GetUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.GetUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.GetUserReq}
 */
proto.org.couchers.api.core.GetUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.GetUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.GetUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.GetUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.org.couchers.api.core.GetUserReq.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.GetUserReq} returns this
 */
proto.org.couchers.api.core.GetUserReq.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.LiteUser.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.LiteUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.LiteUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.LiteUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    age: jspb.Message.getFieldWithDefault(msg, 5, 0),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    avatarThumbnailUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    radius: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    hasStrongVerification: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.LiteUser}
 */
proto.org.couchers.api.core.LiteUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.LiteUser;
  return proto.org.couchers.api.core.LiteUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.LiteUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.LiteUser}
 */
proto.org.couchers.api.core.LiteUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAge(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarThumbnailUrl(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLng(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRadius(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasStrongVerification(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.LiteUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.LiteUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.LiteUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.LiteUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvatarThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getRadius();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getHasStrongVerification();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.org.couchers.api.core.LiteUser.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.org.couchers.api.core.LiteUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.org.couchers.api.core.LiteUser.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.org.couchers.api.core.LiteUser.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 age = 5;
 * @return {number}
 */
proto.org.couchers.api.core.LiteUser.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string avatar_url = 6;
 * @return {string}
 */
proto.org.couchers.api.core.LiteUser.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string avatar_thumbnail_url = 7;
 * @return {string}
 */
proto.org.couchers.api.core.LiteUser.prototype.getAvatarThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setAvatarThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double lat = 8;
 * @return {number}
 */
proto.org.couchers.api.core.LiteUser.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double lng = 9;
 * @return {number}
 */
proto.org.couchers.api.core.LiteUser.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double radius = 10;
 * @return {number}
 */
proto.org.couchers.api.core.LiteUser.prototype.getRadius = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setRadius = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional bool has_strong_verification = 11;
 * @return {boolean}
 */
proto.org.couchers.api.core.LiteUser.prototype.getHasStrongVerification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.LiteUser} returns this
 */
proto.org.couchers.api.core.LiteUser.prototype.setHasStrongVerification = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.GetLiteUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.GetLiteUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.GetLiteUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetLiteUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.GetLiteUserReq}
 */
proto.org.couchers.api.core.GetLiteUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.GetLiteUserReq;
  return proto.org.couchers.api.core.GetLiteUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.GetLiteUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.GetLiteUserReq}
 */
proto.org.couchers.api.core.GetLiteUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.GetLiteUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.GetLiteUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.GetLiteUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetLiteUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.org.couchers.api.core.GetLiteUserReq.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.GetLiteUserReq} returns this
 */
proto.org.couchers.api.core.GetLiteUserReq.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.GetLiteUsersReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.GetLiteUsersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.GetLiteUsersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.GetLiteUsersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetLiteUsersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.GetLiteUsersReq}
 */
proto.org.couchers.api.core.GetLiteUsersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.GetLiteUsersReq;
  return proto.org.couchers.api.core.GetLiteUsersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.GetLiteUsersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.GetLiteUsersReq}
 */
proto.org.couchers.api.core.GetLiteUsersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.GetLiteUsersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.GetLiteUsersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.GetLiteUsersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetLiteUsersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string users = 1;
 * @return {!Array<string>}
 */
proto.org.couchers.api.core.GetLiteUsersReq.prototype.getUsersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.org.couchers.api.core.GetLiteUsersReq} returns this
 */
proto.org.couchers.api.core.GetLiteUsersReq.prototype.setUsersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.GetLiteUsersReq} returns this
 */
proto.org.couchers.api.core.GetLiteUsersReq.prototype.addUsers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.GetLiteUsersReq} returns this
 */
proto.org.couchers.api.core.GetLiteUsersReq.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.org.couchers.api.core.LiteUserRes.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.org.couchers.api.core.LiteUserRes.RespCase = {
  RESP_NOT_SET: 0,
  USER: 2,
  NOT_FOUND: 3
};

/**
 * @return {proto.org.couchers.api.core.LiteUserRes.RespCase}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.getRespCase = function() {
  return /** @type {proto.org.couchers.api.core.LiteUserRes.RespCase} */(jspb.Message.computeOneofCase(this, proto.org.couchers.api.core.LiteUserRes.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.LiteUserRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.LiteUserRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.LiteUserRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.org.couchers.api.core.LiteUser.toObject(includeInstance, f),
    notFound: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.LiteUserRes}
 */
proto.org.couchers.api.core.LiteUserRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.LiteUserRes;
  return proto.org.couchers.api.core.LiteUserRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.LiteUserRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.LiteUserRes}
 */
proto.org.couchers.api.core.LiteUserRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = new proto.org.couchers.api.core.LiteUser;
      reader.readMessage(value,proto.org.couchers.api.core.LiteUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotFound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.LiteUserRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.LiteUserRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.LiteUserRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.org.couchers.api.core.LiteUser.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.LiteUserRes} returns this
 */
proto.org.couchers.api.core.LiteUserRes.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LiteUser user = 2;
 * @return {?proto.org.couchers.api.core.LiteUser}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.getUser = function() {
  return /** @type{?proto.org.couchers.api.core.LiteUser} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.LiteUser, 2));
};


/**
 * @param {?proto.org.couchers.api.core.LiteUser|undefined} value
 * @return {!proto.org.couchers.api.core.LiteUserRes} returns this
*/
proto.org.couchers.api.core.LiteUserRes.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.org.couchers.api.core.LiteUserRes.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.LiteUserRes} returns this
 */
proto.org.couchers.api.core.LiteUserRes.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool not_found = 3;
 * @return {boolean}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.getNotFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.org.couchers.api.core.LiteUserRes} returns this
 */
proto.org.couchers.api.core.LiteUserRes.prototype.setNotFound = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.org.couchers.api.core.LiteUserRes.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.org.couchers.api.core.LiteUserRes} returns this
 */
proto.org.couchers.api.core.LiteUserRes.prototype.clearNotFound = function() {
  return jspb.Message.setOneofField(this, 3, proto.org.couchers.api.core.LiteUserRes.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.LiteUserRes.prototype.hasNotFound = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.GetLiteUsersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.GetLiteUsersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.GetLiteUsersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.GetLiteUsersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetLiteUsersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.org.couchers.api.core.LiteUserRes.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.GetLiteUsersRes}
 */
proto.org.couchers.api.core.GetLiteUsersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.GetLiteUsersRes;
  return proto.org.couchers.api.core.GetLiteUsersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.GetLiteUsersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.GetLiteUsersRes}
 */
proto.org.couchers.api.core.GetLiteUsersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.core.LiteUserRes;
      reader.readMessage(value,proto.org.couchers.api.core.LiteUserRes.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.GetLiteUsersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.GetLiteUsersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.GetLiteUsersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.GetLiteUsersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.core.LiteUserRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LiteUserRes responses = 1;
 * @return {!Array<!proto.org.couchers.api.core.LiteUserRes>}
 */
proto.org.couchers.api.core.GetLiteUsersRes.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.org.couchers.api.core.LiteUserRes>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.core.LiteUserRes, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.LiteUserRes>} value
 * @return {!proto.org.couchers.api.core.GetLiteUsersRes} returns this
*/
proto.org.couchers.api.core.GetLiteUsersRes.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.core.LiteUserRes=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.LiteUserRes}
 */
proto.org.couchers.api.core.GetLiteUsersRes.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.core.LiteUserRes, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.GetLiteUsersRes} returns this
 */
proto.org.couchers.api.core.GetLiteUsersRes.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.RepeatedStringValue.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.RepeatedStringValue.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.RepeatedStringValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.RepeatedStringValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.RepeatedStringValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.RepeatedStringValue}
 */
proto.org.couchers.api.core.RepeatedStringValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.RepeatedStringValue;
  return proto.org.couchers.api.core.RepeatedStringValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.RepeatedStringValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.RepeatedStringValue}
 */
proto.org.couchers.api.core.RepeatedStringValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.RepeatedStringValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.RepeatedStringValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.RepeatedStringValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.RepeatedStringValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string value = 2;
 * @return {!Array<string>}
 */
proto.org.couchers.api.core.RepeatedStringValue.prototype.getValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.org.couchers.api.core.RepeatedStringValue} returns this
 */
proto.org.couchers.api.core.RepeatedStringValue.prototype.setValueList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.RepeatedStringValue} returns this
 */
proto.org.couchers.api.core.RepeatedStringValue.prototype.addValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.RepeatedStringValue} returns this
 */
proto.org.couchers.api.core.RepeatedStringValue.prototype.clearValueList = function() {
  return this.setValueList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.RepeatedLanguageAbilityValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueList: jspb.Message.toObjectList(msg.getValueList(),
    proto.org.couchers.api.core.LanguageAbility.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue}
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.RepeatedLanguageAbilityValue;
  return proto.org.couchers.api.core.RepeatedLanguageAbilityValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue}
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.org.couchers.api.core.LanguageAbility;
      reader.readMessage(value,proto.org.couchers.api.core.LanguageAbility.deserializeBinaryFromReader);
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.RepeatedLanguageAbilityValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.org.couchers.api.core.LanguageAbility.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LanguageAbility value = 1;
 * @return {!Array<!proto.org.couchers.api.core.LanguageAbility>}
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.prototype.getValueList = function() {
  return /** @type{!Array<!proto.org.couchers.api.core.LanguageAbility>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.org.couchers.api.core.LanguageAbility, 1));
};


/**
 * @param {!Array<!proto.org.couchers.api.core.LanguageAbility>} value
 * @return {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue} returns this
*/
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.prototype.setValueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.org.couchers.api.core.LanguageAbility=} opt_value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.LanguageAbility}
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.prototype.addValue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.org.couchers.api.core.LanguageAbility, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.RepeatedLanguageAbilityValue} returns this
 */
proto.org.couchers.api.core.RepeatedLanguageAbilityValue.prototype.clearValueList = function() {
  return this.setValueList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.UpdateProfileReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.UpdateProfileReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.UpdateProfileReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    hometown: (f = msg.getHometown()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    lat: (f = msg.getLat()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    lng: (f = msg.getLng()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    radius: (f = msg.getRadius()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    avatarKey: (f = msg.getAvatarKey()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    pronouns: (f = msg.getPronouns()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    occupation: (f = msg.getOccupation()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    education: (f = msg.getEducation()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    aboutMe: (f = msg.getAboutMe()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    thingsILike: (f = msg.getThingsILike()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    aboutPlace: (f = msg.getAboutPlace()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    hostingStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
    meetupStatus: jspb.Message.getFieldWithDefault(msg, 105, 0),
    regionsVisited: (f = msg.getRegionsVisited()) && proto.org.couchers.api.core.RepeatedStringValue.toObject(includeInstance, f),
    regionsLived: (f = msg.getRegionsLived()) && proto.org.couchers.api.core.RepeatedStringValue.toObject(includeInstance, f),
    additionalInformation: (f = msg.getAdditionalInformation()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    maxGuests: (f = msg.getMaxGuests()) && proto.org.couchers.api.core.NullableUInt32Value.toObject(includeInstance, f),
    lastMinute: (f = msg.getLastMinute()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    hasPets: (f = msg.getHasPets()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    acceptsPets: (f = msg.getAcceptsPets()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    petDetails: (f = msg.getPetDetails()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    hasKids: (f = msg.getHasKids()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    acceptsKids: (f = msg.getAcceptsKids()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    kidDetails: (f = msg.getKidDetails()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    hasHousemates: (f = msg.getHasHousemates()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    housemateDetails: (f = msg.getHousemateDetails()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    wheelchairAccessible: (f = msg.getWheelchairAccessible()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    smokingAllowed: jspb.Message.getFieldWithDefault(msg, 18, 0),
    smokesAtHome: (f = msg.getSmokesAtHome()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    drinkingAllowed: (f = msg.getDrinkingAllowed()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    drinksAtHome: (f = msg.getDrinksAtHome()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    otherHostInfo: (f = msg.getOtherHostInfo()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    sleepingArrangement: jspb.Message.getFieldWithDefault(msg, 117, 0),
    sleepingDetails: (f = msg.getSleepingDetails()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    area: (f = msg.getArea()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    houseRules: (f = msg.getHouseRules()) && proto.org.couchers.api.core.NullableStringValue.toObject(includeInstance, f),
    parking: (f = msg.getParking()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    parkingDetails: jspb.Message.getFieldWithDefault(msg, 120, 0),
    campingOk: (f = msg.getCampingOk()) && proto.org.couchers.api.core.NullableBoolValue.toObject(includeInstance, f),
    languageAbilities: (f = msg.getLanguageAbilities()) && proto.org.couchers.api.core.RepeatedLanguageAbilityValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq}
 */
proto.org.couchers.api.core.UpdateProfileReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.UpdateProfileReq;
  return proto.org.couchers.api.core.UpdateProfileReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.UpdateProfileReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq}
 */
proto.org.couchers.api.core.UpdateProfileReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 100:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setHometown(value);
      break;
    case 22:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setLat(value);
      break;
    case 23:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setLng(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setRadius(value);
      break;
    case 25:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setAvatarKey(value);
      break;
    case 101:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setPronouns(value);
      break;
    case 4:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setOccupation(value);
      break;
    case 102:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setEducation(value);
      break;
    case 5:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setAboutMe(value);
      break;
    case 104:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setThingsILike(value);
      break;
    case 6:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setAboutPlace(value);
      break;
    case 8:
      var value = /** @type {!proto.org.couchers.api.core.HostingStatus} */ (reader.readEnum());
      msg.setHostingStatus(value);
      break;
    case 105:
      var value = /** @type {!proto.org.couchers.api.core.MeetupStatus} */ (reader.readEnum());
      msg.setMeetupStatus(value);
      break;
    case 30:
      var value = new proto.org.couchers.api.core.RepeatedStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.RepeatedStringValue.deserializeBinaryFromReader);
      msg.setRegionsVisited(value);
      break;
    case 31:
      var value = new proto.org.couchers.api.core.RepeatedStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.RepeatedStringValue.deserializeBinaryFromReader);
      msg.setRegionsLived(value);
      break;
    case 106:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setAdditionalInformation(value);
      break;
    case 12:
      var value = new proto.org.couchers.api.core.NullableUInt32Value;
      reader.readMessage(value,proto.org.couchers.api.core.NullableUInt32Value.deserializeBinaryFromReader);
      msg.setMaxGuests(value);
      break;
    case 14:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setLastMinute(value);
      break;
    case 107:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setHasPets(value);
      break;
    case 15:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setAcceptsPets(value);
      break;
    case 108:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setPetDetails(value);
      break;
    case 109:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setHasKids(value);
      break;
    case 16:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setAcceptsKids(value);
      break;
    case 110:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setKidDetails(value);
      break;
    case 111:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setHasHousemates(value);
      break;
    case 112:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setHousemateDetails(value);
      break;
    case 17:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setWheelchairAccessible(value);
      break;
    case 18:
      var value = /** @type {!proto.org.couchers.api.core.SmokingLocation} */ (reader.readEnum());
      msg.setSmokingAllowed(value);
      break;
    case 113:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setSmokesAtHome(value);
      break;
    case 114:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setDrinkingAllowed(value);
      break;
    case 115:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setDrinksAtHome(value);
      break;
    case 116:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setOtherHostInfo(value);
      break;
    case 117:
      var value = /** @type {!proto.org.couchers.api.core.SleepingArrangement} */ (reader.readEnum());
      msg.setSleepingArrangement(value);
      break;
    case 118:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setSleepingDetails(value);
      break;
    case 20:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 21:
      var value = new proto.org.couchers.api.core.NullableStringValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableStringValue.deserializeBinaryFromReader);
      msg.setHouseRules(value);
      break;
    case 119:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setParking(value);
      break;
    case 120:
      var value = /** @type {!proto.org.couchers.api.core.ParkingDetails} */ (reader.readEnum());
      msg.setParkingDetails(value);
      break;
    case 121:
      var value = new proto.org.couchers.api.core.NullableBoolValue;
      reader.readMessage(value,proto.org.couchers.api.core.NullableBoolValue.deserializeBinaryFromReader);
      msg.setCampingOk(value);
      break;
    case 26:
      var value = new proto.org.couchers.api.core.RepeatedLanguageAbilityValue;
      reader.readMessage(value,proto.org.couchers.api.core.RepeatedLanguageAbilityValue.deserializeBinaryFromReader);
      msg.setLanguageAbilities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.UpdateProfileReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.UpdateProfileReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.UpdateProfileReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHometown();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getLat();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getLng();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getRadius();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getAvatarKey();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getPronouns();
  if (f != null) {
    writer.writeMessage(
      101,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getOccupation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getEducation();
  if (f != null) {
    writer.writeMessage(
      102,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getAboutMe();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getThingsILike();
  if (f != null) {
    writer.writeMessage(
      104,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getAboutPlace();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getHostingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getMeetupStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      105,
      f
    );
  }
  f = message.getRegionsVisited();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.org.couchers.api.core.RepeatedStringValue.serializeBinaryToWriter
    );
  }
  f = message.getRegionsLived();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.org.couchers.api.core.RepeatedStringValue.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalInformation();
  if (f != null) {
    writer.writeMessage(
      106,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getMaxGuests();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.org.couchers.api.core.NullableUInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getLastMinute();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasPets();
  if (f != null) {
    writer.writeMessage(
      107,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAcceptsPets();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPetDetails();
  if (f != null) {
    writer.writeMessage(
      108,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getHasKids();
  if (f != null) {
    writer.writeMessage(
      109,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAcceptsKids();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getKidDetails();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getHasHousemates();
  if (f != null) {
    writer.writeMessage(
      111,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHousemateDetails();
  if (f != null) {
    writer.writeMessage(
      112,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getWheelchairAccessible();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSmokingAllowed();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getSmokesAtHome();
  if (f != null) {
    writer.writeMessage(
      113,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDrinkingAllowed();
  if (f != null) {
    writer.writeMessage(
      114,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDrinksAtHome();
  if (f != null) {
    writer.writeMessage(
      115,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOtherHostInfo();
  if (f != null) {
    writer.writeMessage(
      116,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getSleepingArrangement();
  if (f !== 0.0) {
    writer.writeEnum(
      117,
      f
    );
  }
  f = message.getSleepingDetails();
  if (f != null) {
    writer.writeMessage(
      118,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getHouseRules();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.org.couchers.api.core.NullableStringValue.serializeBinaryToWriter
    );
  }
  f = message.getParking();
  if (f != null) {
    writer.writeMessage(
      119,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getParkingDetails();
  if (f !== 0.0) {
    writer.writeEnum(
      120,
      f
    );
  }
  f = message.getCampingOk();
  if (f != null) {
    writer.writeMessage(
      121,
      f,
      proto.org.couchers.api.core.NullableBoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLanguageAbilities();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.org.couchers.api.core.RepeatedLanguageAbilityValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue city = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasCity = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NullableStringValue hometown = 100;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHometown = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 100));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHometown = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearHometown = function() {
  return this.setHometown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasHometown = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional google.protobuf.DoubleValue lat = 22;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getLat = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 22));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setLat = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearLat = function() {
  return this.setLat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasLat = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional google.protobuf.DoubleValue lng = 23;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getLng = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 23));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setLng = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearLng = function() {
  return this.setLng(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasLng = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.DoubleValue radius = 24;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getRadius = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 24));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setRadius = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearRadius = function() {
  return this.setRadius(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasRadius = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional NullableStringValue avatar_key = 25;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getAvatarKey = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 25));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setAvatarKey = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearAvatarKey = function() {
  return this.setAvatarKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasAvatarKey = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional NullableStringValue pronouns = 101;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getPronouns = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 101));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setPronouns = function(value) {
  return jspb.Message.setWrapperField(this, 101, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearPronouns = function() {
  return this.setPronouns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasPronouns = function() {
  return jspb.Message.getField(this, 101) != null;
};


/**
 * optional NullableStringValue occupation = 4;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getOccupation = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 4));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setOccupation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearOccupation = function() {
  return this.setOccupation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasOccupation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NullableStringValue education = 102;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getEducation = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 102));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setEducation = function(value) {
  return jspb.Message.setWrapperField(this, 102, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearEducation = function() {
  return this.setEducation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasEducation = function() {
  return jspb.Message.getField(this, 102) != null;
};


/**
 * optional NullableStringValue about_me = 5;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getAboutMe = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 5));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setAboutMe = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearAboutMe = function() {
  return this.setAboutMe(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasAboutMe = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional NullableStringValue things_i_like = 104;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getThingsILike = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 104));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setThingsILike = function(value) {
  return jspb.Message.setWrapperField(this, 104, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearThingsILike = function() {
  return this.setThingsILike(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasThingsILike = function() {
  return jspb.Message.getField(this, 104) != null;
};


/**
 * optional NullableStringValue about_place = 6;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getAboutPlace = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 6));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setAboutPlace = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearAboutPlace = function() {
  return this.setAboutPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasAboutPlace = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HostingStatus hosting_status = 8;
 * @return {!proto.org.couchers.api.core.HostingStatus}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHostingStatus = function() {
  return /** @type {!proto.org.couchers.api.core.HostingStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.org.couchers.api.core.HostingStatus} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHostingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional MeetupStatus meetup_status = 105;
 * @return {!proto.org.couchers.api.core.MeetupStatus}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getMeetupStatus = function() {
  return /** @type {!proto.org.couchers.api.core.MeetupStatus} */ (jspb.Message.getFieldWithDefault(this, 105, 0));
};


/**
 * @param {!proto.org.couchers.api.core.MeetupStatus} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.setMeetupStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 105, value);
};


/**
 * optional RepeatedStringValue regions_visited = 30;
 * @return {?proto.org.couchers.api.core.RepeatedStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getRegionsVisited = function() {
  return /** @type{?proto.org.couchers.api.core.RepeatedStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.RepeatedStringValue, 30));
};


/**
 * @param {?proto.org.couchers.api.core.RepeatedStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setRegionsVisited = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearRegionsVisited = function() {
  return this.setRegionsVisited(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasRegionsVisited = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional RepeatedStringValue regions_lived = 31;
 * @return {?proto.org.couchers.api.core.RepeatedStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getRegionsLived = function() {
  return /** @type{?proto.org.couchers.api.core.RepeatedStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.RepeatedStringValue, 31));
};


/**
 * @param {?proto.org.couchers.api.core.RepeatedStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setRegionsLived = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearRegionsLived = function() {
  return this.setRegionsLived(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasRegionsLived = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional NullableStringValue additional_information = 106;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getAdditionalInformation = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 106));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setAdditionalInformation = function(value) {
  return jspb.Message.setWrapperField(this, 106, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearAdditionalInformation = function() {
  return this.setAdditionalInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasAdditionalInformation = function() {
  return jspb.Message.getField(this, 106) != null;
};


/**
 * optional NullableUInt32Value max_guests = 12;
 * @return {?proto.org.couchers.api.core.NullableUInt32Value}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getMaxGuests = function() {
  return /** @type{?proto.org.couchers.api.core.NullableUInt32Value} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableUInt32Value, 12));
};


/**
 * @param {?proto.org.couchers.api.core.NullableUInt32Value|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setMaxGuests = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearMaxGuests = function() {
  return this.setMaxGuests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasMaxGuests = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional NullableBoolValue last_minute = 14;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getLastMinute = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 14));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setLastMinute = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearLastMinute = function() {
  return this.setLastMinute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasLastMinute = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional NullableBoolValue has_pets = 107;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHasPets = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 107));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHasPets = function(value) {
  return jspb.Message.setWrapperField(this, 107, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearHasPets = function() {
  return this.setHasPets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasHasPets = function() {
  return jspb.Message.getField(this, 107) != null;
};


/**
 * optional NullableBoolValue accepts_pets = 15;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getAcceptsPets = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 15));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setAcceptsPets = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearAcceptsPets = function() {
  return this.setAcceptsPets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasAcceptsPets = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional NullableStringValue pet_details = 108;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getPetDetails = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 108));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setPetDetails = function(value) {
  return jspb.Message.setWrapperField(this, 108, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearPetDetails = function() {
  return this.setPetDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasPetDetails = function() {
  return jspb.Message.getField(this, 108) != null;
};


/**
 * optional NullableBoolValue has_kids = 109;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHasKids = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 109));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHasKids = function(value) {
  return jspb.Message.setWrapperField(this, 109, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearHasKids = function() {
  return this.setHasKids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasHasKids = function() {
  return jspb.Message.getField(this, 109) != null;
};


/**
 * optional NullableBoolValue accepts_kids = 16;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getAcceptsKids = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 16));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setAcceptsKids = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearAcceptsKids = function() {
  return this.setAcceptsKids(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasAcceptsKids = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional NullableStringValue kid_details = 110;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getKidDetails = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 110));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setKidDetails = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearKidDetails = function() {
  return this.setKidDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasKidDetails = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional NullableBoolValue has_housemates = 111;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHasHousemates = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 111));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHasHousemates = function(value) {
  return jspb.Message.setWrapperField(this, 111, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearHasHousemates = function() {
  return this.setHasHousemates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasHasHousemates = function() {
  return jspb.Message.getField(this, 111) != null;
};


/**
 * optional NullableStringValue housemate_details = 112;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHousemateDetails = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 112));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHousemateDetails = function(value) {
  return jspb.Message.setWrapperField(this, 112, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearHousemateDetails = function() {
  return this.setHousemateDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasHousemateDetails = function() {
  return jspb.Message.getField(this, 112) != null;
};


/**
 * optional NullableBoolValue wheelchair_accessible = 17;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getWheelchairAccessible = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 17));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setWheelchairAccessible = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearWheelchairAccessible = function() {
  return this.setWheelchairAccessible(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasWheelchairAccessible = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional SmokingLocation smoking_allowed = 18;
 * @return {!proto.org.couchers.api.core.SmokingLocation}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getSmokingAllowed = function() {
  return /** @type {!proto.org.couchers.api.core.SmokingLocation} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.org.couchers.api.core.SmokingLocation} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.setSmokingAllowed = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional NullableBoolValue smokes_at_home = 113;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getSmokesAtHome = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 113));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setSmokesAtHome = function(value) {
  return jspb.Message.setWrapperField(this, 113, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearSmokesAtHome = function() {
  return this.setSmokesAtHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasSmokesAtHome = function() {
  return jspb.Message.getField(this, 113) != null;
};


/**
 * optional NullableBoolValue drinking_allowed = 114;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getDrinkingAllowed = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 114));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setDrinkingAllowed = function(value) {
  return jspb.Message.setWrapperField(this, 114, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearDrinkingAllowed = function() {
  return this.setDrinkingAllowed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasDrinkingAllowed = function() {
  return jspb.Message.getField(this, 114) != null;
};


/**
 * optional NullableBoolValue drinks_at_home = 115;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getDrinksAtHome = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 115));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setDrinksAtHome = function(value) {
  return jspb.Message.setWrapperField(this, 115, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearDrinksAtHome = function() {
  return this.setDrinksAtHome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasDrinksAtHome = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional NullableStringValue other_host_info = 116;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getOtherHostInfo = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 116));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setOtherHostInfo = function(value) {
  return jspb.Message.setWrapperField(this, 116, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearOtherHostInfo = function() {
  return this.setOtherHostInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasOtherHostInfo = function() {
  return jspb.Message.getField(this, 116) != null;
};


/**
 * optional SleepingArrangement sleeping_arrangement = 117;
 * @return {!proto.org.couchers.api.core.SleepingArrangement}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getSleepingArrangement = function() {
  return /** @type {!proto.org.couchers.api.core.SleepingArrangement} */ (jspb.Message.getFieldWithDefault(this, 117, 0));
};


/**
 * @param {!proto.org.couchers.api.core.SleepingArrangement} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.setSleepingArrangement = function(value) {
  return jspb.Message.setProto3EnumField(this, 117, value);
};


/**
 * optional NullableStringValue sleeping_details = 118;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getSleepingDetails = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 118));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setSleepingDetails = function(value) {
  return jspb.Message.setWrapperField(this, 118, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearSleepingDetails = function() {
  return this.setSleepingDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasSleepingDetails = function() {
  return jspb.Message.getField(this, 118) != null;
};


/**
 * optional NullableStringValue area = 20;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getArea = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 20));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasArea = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional NullableStringValue house_rules = 21;
 * @return {?proto.org.couchers.api.core.NullableStringValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getHouseRules = function() {
  return /** @type{?proto.org.couchers.api.core.NullableStringValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableStringValue, 21));
};


/**
 * @param {?proto.org.couchers.api.core.NullableStringValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setHouseRules = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearHouseRules = function() {
  return this.setHouseRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasHouseRules = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional NullableBoolValue parking = 119;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getParking = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 119));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setParking = function(value) {
  return jspb.Message.setWrapperField(this, 119, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearParking = function() {
  return this.setParking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasParking = function() {
  return jspb.Message.getField(this, 119) != null;
};


/**
 * optional ParkingDetails parking_details = 120;
 * @return {!proto.org.couchers.api.core.ParkingDetails}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getParkingDetails = function() {
  return /** @type {!proto.org.couchers.api.core.ParkingDetails} */ (jspb.Message.getFieldWithDefault(this, 120, 0));
};


/**
 * @param {!proto.org.couchers.api.core.ParkingDetails} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.setParkingDetails = function(value) {
  return jspb.Message.setProto3EnumField(this, 120, value);
};


/**
 * optional NullableBoolValue camping_ok = 121;
 * @return {?proto.org.couchers.api.core.NullableBoolValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getCampingOk = function() {
  return /** @type{?proto.org.couchers.api.core.NullableBoolValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.NullableBoolValue, 121));
};


/**
 * @param {?proto.org.couchers.api.core.NullableBoolValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setCampingOk = function(value) {
  return jspb.Message.setWrapperField(this, 121, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearCampingOk = function() {
  return this.setCampingOk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasCampingOk = function() {
  return jspb.Message.getField(this, 121) != null;
};


/**
 * optional RepeatedLanguageAbilityValue language_abilities = 26;
 * @return {?proto.org.couchers.api.core.RepeatedLanguageAbilityValue}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.getLanguageAbilities = function() {
  return /** @type{?proto.org.couchers.api.core.RepeatedLanguageAbilityValue} */ (
    jspb.Message.getWrapperField(this, proto.org.couchers.api.core.RepeatedLanguageAbilityValue, 26));
};


/**
 * @param {?proto.org.couchers.api.core.RepeatedLanguageAbilityValue|undefined} value
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
*/
proto.org.couchers.api.core.UpdateProfileReq.prototype.setLanguageAbilities = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.UpdateProfileReq} returns this
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.clearLanguageAbilities = function() {
  return this.setLanguageAbilities(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.UpdateProfileReq.prototype.hasLanguageAbilities = function() {
  return jspb.Message.getField(this, 26) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.InitiateMediaUploadRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.InitiateMediaUploadRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    uploadUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiry: (f = msg.getExpiry()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.InitiateMediaUploadRes}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.InitiateMediaUploadRes;
  return proto.org.couchers.api.core.InitiateMediaUploadRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.InitiateMediaUploadRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.InitiateMediaUploadRes}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploadUrl(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.InitiateMediaUploadRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.InitiateMediaUploadRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUploadUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string upload_url = 1;
 * @return {string}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.getUploadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.InitiateMediaUploadRes} returns this
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.setUploadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp expiry = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.getExpiry = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.org.couchers.api.core.InitiateMediaUploadRes} returns this
*/
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.setExpiry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.org.couchers.api.core.InitiateMediaUploadRes} returns this
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.clearExpiry = function() {
  return this.setExpiry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.org.couchers.api.core.InitiateMediaUploadRes.prototype.hasExpiry = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.ListBadgeUsersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.ListBadgeUsersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListBadgeUsersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    badgeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.ListBadgeUsersReq}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.ListBadgeUsersReq;
  return proto.org.couchers.api.core.ListBadgeUsersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.ListBadgeUsersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.ListBadgeUsersReq}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBadgeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.ListBadgeUsersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.ListBadgeUsersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListBadgeUsersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBadgeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string badge_id = 1;
 * @return {string}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.getBadgeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.ListBadgeUsersReq} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.setBadgeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 page_size = 2;
 * @return {number}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.org.couchers.api.core.ListBadgeUsersReq} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.ListBadgeUsersReq} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersReq.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.org.couchers.api.core.ListBadgeUsersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.org.couchers.api.core.ListBadgeUsersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.org.couchers.api.core.ListBadgeUsersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListBadgeUsersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.org.couchers.api.core.ListBadgeUsersRes}
 */
proto.org.couchers.api.core.ListBadgeUsersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.org.couchers.api.core.ListBadgeUsersRes;
  return proto.org.couchers.api.core.ListBadgeUsersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.org.couchers.api.core.ListBadgeUsersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.org.couchers.api.core.ListBadgeUsersRes}
 */
proto.org.couchers.api.core.ListBadgeUsersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUserIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.org.couchers.api.core.ListBadgeUsersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.org.couchers.api.core.ListBadgeUsersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.org.couchers.api.core.ListBadgeUsersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int64 user_ids = 1;
 * @return {!Array<number>}
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.org.couchers.api.core.ListBadgeUsersRes} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.org.couchers.api.core.ListBadgeUsersRes} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.org.couchers.api.core.ListBadgeUsersRes} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.org.couchers.api.core.ListBadgeUsersRes} returns this
 */
proto.org.couchers.api.core.ListBadgeUsersRes.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.org.couchers.api.core.HostingStatus = {
  HOSTING_STATUS_UNSPECIFIED: 0,
  HOSTING_STATUS_UNKNOWN: 1,
  HOSTING_STATUS_CAN_HOST: 2,
  HOSTING_STATUS_MAYBE: 3,
  HOSTING_STATUS_CANT_HOST: 4
};

/**
 * @enum {number}
 */
proto.org.couchers.api.core.MeetupStatus = {
  MEETUP_STATUS_UNSPECIFIED: 0,
  MEETUP_STATUS_UNKNOWN: 1,
  MEETUP_STATUS_WANTS_TO_MEETUP: 2,
  MEETUP_STATUS_OPEN_TO_MEETUP: 3,
  MEETUP_STATUS_DOES_NOT_WANT_TO_MEETUP: 4
};

/**
 * @enum {number}
 */
proto.org.couchers.api.core.SmokingLocation = {
  SMOKING_LOCATION_UNSPECIFIED: 0,
  SMOKING_LOCATION_UNKNOWN: 1,
  SMOKING_LOCATION_YES: 2,
  SMOKING_LOCATION_WINDOW: 3,
  SMOKING_LOCATION_OUTSIDE: 4,
  SMOKING_LOCATION_NO: 5
};

/**
 * @enum {number}
 */
proto.org.couchers.api.core.SleepingArrangement = {
  SLEEPING_ARRANGEMENT_UNSPECIFIED: 0,
  SLEEPING_ARRANGEMENT_UNKNOWN: 1,
  SLEEPING_ARRANGEMENT_PRIVATE: 2,
  SLEEPING_ARRANGEMENT_COMMON: 3,
  SLEEPING_ARRANGEMENT_SHARED_ROOM: 4
};

/**
 * @enum {number}
 */
proto.org.couchers.api.core.ParkingDetails = {
  PARKING_DETAILS_UNSPECIFIED: 0,
  PARKING_DETAILS_UNKNOWN: 1,
  PARKING_DETAILS_FREE_ONSITE: 2,
  PARKING_DETAILS_FREE_OFFSITE: 3,
  PARKING_DETAILS_PAID_ONSITE: 4,
  PARKING_DETAILS_PAID_OFFSITE: 5
};

/**
 * @enum {number}
 */
proto.org.couchers.api.core.BirthdateVerificationStatus = {
  BIRTHDATE_VERIFICATION_STATUS_UNSPECIFIED: 0,
  BIRTHDATE_VERIFICATION_STATUS_UNVERIFIED: 1,
  BIRTHDATE_VERIFICATION_STATUS_VERIFIED: 2,
  BIRTHDATE_VERIFICATION_STATUS_MISMATCH: 3
};

/**
 * @enum {number}
 */
proto.org.couchers.api.core.GenderVerificationStatus = {
  GENDER_VERIFICATION_STATUS_UNSPECIFIED: 0,
  GENDER_VERIFICATION_STATUS_UNVERIFIED: 1,
  GENDER_VERIFICATION_STATUS_VERIFIED: 2,
  GENDER_VERIFICATION_STATUS_MISMATCH: 3
};

goog.object.extend(exports, proto.org.couchers.api.core);
